/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';

const inBrowser = typeof navigator !== 'undefined';

const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;

const ping = ({ url, timeout }) =>
  new Promise((resolve) => {
    const isOnline = () => resolve(true);
    const isOffline = () => resolve(false);

    // eslint-disable-next-line no-undef
    const xhr = new XMLHttpRequest();

    xhr.onerror = isOffline;
    xhr.ontimeout = isOffline;
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED) {
        if (xhr.status) {
          isOnline();
        } else {
          isOffline();
        }
      }
    };

    xhr.open('GET', url);
    xhr.timeout = timeout;
    xhr.send();
  });

// const ping = ({ funcName, timeout, functions }) =>
//   new Promise((resolve) => {
//     const isOnline = () => resolve(true);
//     const isOffline = () => resolve(false);

//     const func = functions.httpsCallable(funcName, { timeout });
//     func().then((r) => {
//       if (r.data?.message) {
//         isOnline();
//       } else {
//         isOffline();
//       }
//     });
//   });

const useInternetState = (props) => {
  const { functions, funcName, url, timeout, interval } = props;
  const [online, setOnline] = useState(true);
  const defaultPollingConfig = {
    enabled:
      inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
    url: url,
    timeout: timeout || 5000,
    interval: interval || 5000
  };
  let pollingId;

  const goOnline = () => {
    setOnline(true);
  };

  const goOffline = () => {
    setOnline(false);
  };

  const getPollingConfig = () => ({ ...defaultPollingConfig, polling: true });

  const startPolling = () => {
    const { interval } = getPollingConfig();
    pollingId = setInterval(() => {
      const { url, timeout } = getPollingConfig();
      ping({ url, functions, funcName, timeout }).then((o) => {
        if (o) goOnline();
        else goOffline();
      });
    }, interval);
  };

  const stopPolling = () => {
    clearInterval(pollingId);
  };

  useEffect(() => {
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);
    if (getPollingConfig().enabled) {
      startPolling();
    }
    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);

      if (pollingId) {
        stopPolling();
      }
    };
  }, []);

  return {
    online
  };
};

export default useInternetState;
